import './App.css';

function App() {
  return (
    <div className="App">
    <div className="annaiuncmentbar">
    <p>we are based in india thats why we are cost effective </p>

    </div>
      <header className="App-header">
      <img src="https://cdn.shopify.com/s/files/1/0694/6612/8628/files/shopifytaskerlogonew-removebg-preview.png?v=1726022913" alt="shopifytaskerlogo" border="0"/>
    <h1> Hire Top Experience shopify Expert from Shopifytasker </h1>
    <h2> Pay after work done No upfrom payment demand </h2>
       


      </header>
    </div>
  );
}

export default App;
